.services-screen-container {
    position: relative;
    height:65vh;
    width: 100%;
    overflow: hidden;
}

.services-content-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(var(--black-20) 15%, transparent 35%, transparent 90%, var(--black-20));
    overflow: hidden;
    z-index: 1;
}

.services-title {
    color: var(--white);
    font-size: 1.4em;
    font-weight: 200;
    margin-top: 25px;
    margin-bottom: 0;
    padding: 0 24px 0 24px;
}

.services-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.services-list-item {
    padding: 0 12px 0 12px;
    font-size: 2em;
    width: fit-content;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke: 0.2px var(--yellow);
}

.services-swiper .active-service, .services-swiper .not-active-service {
    margin-top: 0;
}

.services-swiper {
    width: 100%;
    padding: 0 24px 0 24px !important;
}

.service-swiper-slide {
    width: fit-content;
    text-align: center;
    font-size: 2em;
    width: fit-content;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke: 0.2px var(--yellow);
}

.service-swiper-slide .active-service {
    color: var(--yellow);
}

@media only screen and (min-width: 768px){

    .services-title {
        padding: 0 48px 0 48px;
    }

    .services-screen-container {
        height:45vh;
    }

}

@media only screen and (min-width: 1024px){

    .services-screen-container {
        height: 65vh;
    }

}