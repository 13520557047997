.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 24px;
}

.header-figure {
    margin: 0;
    width: 75px;
    height: 75px;
}

.header-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

#menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    right: 0 !important;
  }
  .menu__btn {
    position: fixed;
    top: 20px;
    right: 35px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 18px;
    background-color: var(--white);
    transition-duration: .5s;
  }
  .menu__btn > span::before {
    content: '';
    top: -10px;
  }
  .menu__btn > span::after {
    content: '';
    top: 10px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    right: -100%;
    width: 300px;
    height: calc(100vh - 75px);
    margin: 0;
    padding: 80px 0 0 0;
    list-style: none;
    background-color: var(--black);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .5s;
  }
  .menu__item {
    display: block;
    padding: 24px 24px 6px;
    color: var(--white);
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
    transition-duration: .5s;
    text-align: center;
    width: fit-content;
    margin: auto;

    position: relative;
    overflow: hidden;
  }
  .menu__item:before {
    content: '';
    position: absolute;
    left: -100%;
    bottom: 0;
    background-color: var(--yellow);
    height: 1px;
    width: 70%;
    margin-left: 15%;
    z-index: -1;
    transition: 0.5s ease;
  }

  .menu__item:hover:before {
    transition: 0.5s ease;
    left: 0;
  }

  .menu__item-active:before {
    content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: var(--yellow);
      height: 1px;
      width: 70%;
      margin-left: 15%;
      z-index: -1;
  }

  .menu__socials_container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  @media only screen and (min-width: 768px){

    .header {
      padding: 12px 48px 0 48px;
    }

  }

  @media only screen and (min-width: 1024px){

    .header {
      width: calc(100% - 96px);
    }

    .nav-bar-list{
      display: flex;
      height: 100%;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    
    .nav-bar-item {
      /* transition: 0.5s ease; */
      text-decoration: none;
      color: var(--white);
      margin: 12px 28px;
      font-size: 19px;
      font-weight: 300;
      border: none;

      display: block;
      position: relative;
      overflow: hidden;

      padding-bottom: 5px;
      padding-top: 5px;
    }

    .nav-bar-item:before {
      content: '';
      position: absolute;
      left: -100%;
      bottom: 0;
      /* background-color: var(--yellow); */
      background-image: linear-gradient(90deg, var(--darker-yellow), var(--yellow) 50%);
        box-shadow: 0px 0px 3px 0px var(--lighter-yellow-50);
      height: 1px;
      width: 100%;
      z-index: -1;
      transition: 0.5s ease;

      margin-bottom: 5px;
    }

    .nav-bar-item:hover:before {
      transition: 0.5s ease;
      left: 0;
    }

  }