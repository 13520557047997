.portfolio-screen-container {
    position: relative;
    width: 100%;
    background-color: var(--black-20);
    padding-bottom: 50px;
    z-index: 1;
}

.portfolio-titles-container {
    position: absolute;
    top: -70px;
}

.portfolio-titles {
    position: relative;
    margin: auto;
    padding-top: 24px;
    display: flex;
    align-items: baseline;
    padding: 12px 24px 0 24px;
}

.portfolio-main-title {
    margin: 0;
    margin-top: 30px;
    color: var(--white);
    font-weight: 600;
    font-size: 2em;
    text-shadow: 0px 0px 10px var(--black-75);
    z-index: 1;
}

.portfolio-background-title {
    position: absolute;
    display: block;
    margin: 0;
    font-size: max(3.6em);
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke: 0.3px var(--yellow);
}

#portfolio-filter {
    margin-top: 35px;
    background-color: transparent;
    color: var(--yellow);
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 1.1em;
    border: none;
    margin-left: 24px;
}

#portfolio-filter:hover {
    cursor: pointer;
}

#portfolio-filter option {
    background-color: var(--black-20);
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 1.1em;
}

.portfolio-carousel {
    margin-top: 0;
    padding-top: 125px;
    display: flex;
    overflow: auto;
    height: auto;
}

.swiper {
    width: 98%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: 100%;
    overflow: hidden;
}

.swiper-wrapper {
    padding-bottom: 45px !important;
}

.swiper-pagination {
    padding-bottom: 45px !important;
}

.swiper-pagination-bullet {
    top: 40px !important;
    transform: scale(1.5) !important
}

.portfolio-swiper-slide {
    height: 400px !important;

    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    max-width: 350px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: -webkit-flex; */
    /* display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-scrollbar {
    opacity: 1 !important;
    height: 2px !important;
    width: 90% !important;
    margin-left: 5%;
    background: var(--black-10) !important;
}

.swiper-scrollbar-drag {
    background: var(--yellow) !important;
}

.swiper-scrollbar-drag:hover {
    height: 25px !important;
}

.swiper-scrollbar:hover {
    height: 25px !important;
}

@media only screen and (max-width: 767px){
    .portfolio-swiper-slide{
        width: 75% !important;
    }
}

@media only screen and (min-width: 768px){

    .portfolio-titles {
        padding: 12px 48px 0 48px;

    }

    .portfolio-titles-container {
        position: unset;
        height: 50px;
    }

    .portfolio-header {
        position: absolute;
        display: flex;
        top: -70px;
    }

    #portfolio-filter {
        margin-left: 10vw;
    }
    .portfolio-carousel {
        padding-top: 25px;
        width: 90%;
        margin: auto;
    }

    .portfolio-swiper-slide {
        max-width: unset;
        /* width: null !important; */
    }

    .portfolio-swiper-slide {
        height: 500px !important;
    }
}

@media only screen and (min-width: 1024px){

    .portfolio-main-title {
        font-size: 3em;
        margin-top: 45px;
    }

    .portfolio-background-title {
        font-size: 5.4em;
    }

    .portfolio-carousel {
        padding-top: 50px;
    }

    .portfolio-swiper-slide {
        max-height: 50vh !important;
    }
}