.background-image-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.background-image-adjustments {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--black-10);
}

.background-image-figure {
    margin: 0;
    height: 100%;
    width: 100%;
}

.background-image-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}