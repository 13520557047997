.background-swiper {
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
}

.background-swiper .swiper-slide {
    width: 100% !important;
}

.background-swiper-figure {
    width: 100%;
    height: 100%;
    margin: 0;
}

.background-swiper-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}