.portfolio-image-modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #202020dd;
    position: fixed;
    z-index: 1;
}

.portfolio-image-modal-figure {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.portfolio-image-modal-figure:hover {
    cursor: pointer;
}

.portfolio-image-modal-image {
    width: fit-content;
    height: fit-content;
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
}

.portfolio-image-modal-cross {
    color: var(--yellow);
    position: relative;
    top: 95px;
    left: 25px;
    font-size: 40px;
    font-weight: 100;
    cursor: pointer;
}