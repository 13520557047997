.banner-screen-container {
  position: relative;
  height: 100vh;
  width: 100;
}

.banner-screen-background {
  width: 100%;
  height: 100%;
}

.banner-screen-content-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
}

.hero-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.hero-video {
  position: relative;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.banner-figure {
  position: absolute;
  margin: 0;
  bottom: 0;
  max-height: 95vh;
  min-width: 120%;
  width: auto;
  padding-left: 25%;
}

.banner-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.banner-vanmierlo {
  position: absolute;
  display: block;
  margin: 0;
  /* font-size: max(54px); */
  font-weight: 900;
  font-size: 6em;
  color: transparent;
  -webkit-text-stroke: 2px var(--yellow);
  transform: rotate(-90deg);
  top: 38%;
  right: calc(-345px + 100vw);
}

.banner-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(transparent 60%, var(--black-20) 98%);
  top: 0;
}

.banner-main-content {
  position: absolute;
  bottom: 7%;
  display: flex;
  width: 100%;
  padding: 0 24px 0 24px;
  justify-content: space-between;
  text-shadow: 0px 0px 40px var(--black-10);
}

.banner-dries {
  font-weight: 900;
  font-size: 4.5em;
  color: transparent;
  -webkit-text-stroke: 0.7px var(--white);
}

.banner-slogan {
  position: absolute;
  margin: 0;
  bottom: -10px;
  color: var(--white);
  font-size: 1.2em;
  font-weight: 100;
}

.banner-arrow {
  position: absolute;
  display: inline;
  margin: auto;
  color: transparent;
  -webkit-text-stroke: 0.5px var(--yellow);
  bottom: 0;
  text-align: center;
  font-size: 4em;
  z-index: 1;
  right: 25vw;
}

.banner-arrow:before {
  position: relative;
  display: inline-block;
  transform: rotate(90deg);
  animation: updown 2.5s infinite ease-in-out;
}

@keyframes updown {
  0% {
    top: 0px;
    opacity: 0;
  }
  25% {
    top: 5px;
    opacity: 1;
  }
  75% {
    top: 25px;
    opacity: 1;
  }
  100% {
    top: 30px;
    opacity: 0;
  }
}

@media only screen and (min-width: 768px) {
  .banner-figure {
    height: 95vh;
    width: auto;
    min-width: 95vh;
  }

  .banner-vanmierlo {
    right: calc(-375px + 100vw);
  }

  .banner-main-content {
    padding: 0 48px 0 48px;
  }

  .banner-dries {
    font-size: 6em;
  }

  .banner-slogan {
    font-size: 1.8em;
  }

  .banner-arrow {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .banner-screen-container {
    width: 100%;
  }

  .banner-figure {
    height: 95%;
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .banner-image {
    height: 100%;
    width: auto;
    margin: auto;
  }

  .banner-vanmierlo {
    transform: none;
    font-size: 16vw;
    right: 0;
    width: 100%;
    text-align: center;
    top: calc(40vh - 7vw);
  }

  .banner-lowerthird {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .banner-main-content {
    width: calc(100% - 136px);
    padding: 0 68px 0 68px;
    bottom: 10%;
    text-shadow: 0px 0px 60px var(--black-20);
  }

  .banner-dries {
    font-size: 10em;
  }

  .banner-slogan {
    position: unset;
    font-size: 3em;
    align-self: center;
    width: 25%;
    text-align: right;
    line-height: 100%;
  }

  .banner-arrow {
    right: unset;
    width: calc(100% - 136px);
    margin: auto;
    padding-bottom: 0;
  }
}
