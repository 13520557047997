@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --black: #000000;
  --black-10: #101010;
  --black-20: #202020;
  --black-30: #303030;
  --yellow: #ffd900;
  --darker-yellow: #b09500;
  --dark-yellow: #837000;
  --lighter-yellow: #fff0aa;
  --white: #ffffff;
  --swiper-theme-color: #ffd900 !important;

  --primary_100: #fff4f4;
  --primary_300: #f3afb3;
  --primary_400: #f29096;
  --primary_500: #e53d45;
  --primary_600: #d33c3c;
  --neutral_100: #fff;
  --neutral_200: #f5f5f5;
  --neutral_300: #e2e0e1;
  --neutral_500: #a6a6a6;
  --neutral_600: #818181;
  --neutral_700: #606161;
  --neutral_900: #000;
  --green_100: #eefff1;
  --green_500: #2ddd49;
  --blue_500: #0085ff;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?u6m2fi");
  src: url("./assets/fonts/icomoon.eot?u6m2fi#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?u6m2fi") format("truetype"),
    url("./assets/fonts/icomoon.woff?u6m2fi") format("woff"),
    url("./assets/fonts/icomoon.svg?u6m2fi#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-maximize:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e902";
}
.icon-right_arrow_small_icon:before {
  content: "\e900";
}
.icon-down_arrow_small_icon:before {
  content: "\e900";
  transform: rotate(90deg);
}
.icon-right_arrow_big_icon:before {
  content: "\e901";
}
.icon-enlarge:before {
  content: "\e98b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-linkedin:before {
  content: "\eaca";
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--black-20);
}