.about-devider-left {
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, var(--yellow) 40%, transparent 60%);
    bottom: 8%;
    z-index: 1;
}

.about-devider-right {
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, transparent 20%, var(--yellow) 40%);
    bottom: 5%;
    z-index: 1;
}