.contact-screen-container {
  width: 100%;
  min-height: 95vh;
  height: 100%;
  background-color: var(--black-20);
  position: relative;
  z-index: 0;

  background-image: url("../../assets/images/contact_screen_background_placeholder.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact-content-container {
  background-image: linear-gradient(var(--black-20) 15%, transparent 50%);
  width: 100%;
  height: 100%;
  top: 0;
  padding-bottom: 25px;
  z-index: 1;
}

.contact-titles {
  position: relative;
  margin: auto;
  padding-top: 24px;
  display: flex;
  align-items: baseline;
  padding: 12px 24px 0 24px;
}

.contact-main-title {
  margin: 0;
  margin-top: 30px;
  color: var(--white);
  font-weight: 600;
  font-size: 2em;
  text-shadow: 0px 0px 10px var(--black-75);
  z-index: 1;
}

.contact-background-title {
  position: absolute;
  display: block;
  margin: 0;
  font-size: max(3.6em);
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 0.3px var(--yellow);
}

.contact-information-title {
  padding: 12px 24px 0 24px;
  color: var(--yellow);
  font-weight: 200;
  font-size: 1.2em;
}

.contact-email,
.contact-phone {
  padding: 0px 24px 0 24px;
  color: var(--white);
  font-weight: 200;
  margin: 0;
}

.contact-socials-container {
  display: flex;
  flex-direction: row;
  width: 50vw;
  padding: 5px 24px 0 15px;
}
.contact-social-icon {
  color: var(--white);
  text-decoration: none;
  padding: 15px;
  font-size: 1.2em;
}

.input-container {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
}

.input-container label {
  color: var(--white);
  font-weight: 200;
  font-size: 1.2em;
}

.input-container input {
  height: 24px;
  background-color: #20202040;
  backdrop-filter: blur(5px);
  border: none;
  border-bottom: 1px solid var(--yellow);
  font-size: 1.1em;
  font-family: "poppins", sans-serif;
  font-weight: 300;
  padding: 5px 10px;
  color: var(--white);
}

.contact-message {
  background-color: #20202040;
  backdrop-filter: blur(5px);
  border: none;
  border-bottom: 1px solid var(--yellow);
  color: var(--white);
  font-size: 1.1em;
  font-family: "poppins", sans-serif;
  font-weight: 300;
  padding: 5px 10px;
}

#contact-submit {
  display: flex;
  color: var(--white);
  background-color: transparent;
  border: 1px solid var(--white);
  padding: 6px 12px;
  font-size: 1.4em;
  margin-left: auto;
  transition: 0.3s ease;
  font-weight: 100;
  margin-right: 24px;
  margin-top: 15px;
  margin-bottom: 25px;
}

#contact-submit:hover {
  background-color: var(--white);
  color: var(--dark-gray);
  transition: 0.3s ease;
  cursor: pointer;
}

.icon-contact {
  padding-top: 3px;
  padding-left: 6px;
}

.copyright-disclaimer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: auto;
  text-align: center;
  backdrop-filter: blur(5px);
  padding-bottom: 10px;
  color: #000000;
}

.copyright-disclaimer a {
  color: #000000;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .contact-titles,
  .contact-information-title,
  .contact-email,
  .contact-phone {
    padding: 12px 48px 0 48px;
  }

  .contact-socials-container {
    padding: 12px 48px 0 36px;
  }

  #contact-form {
    padding: 12px 30px 0 30px;
  }

  .contact-form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .input-container {
    width: 44%;
  }

  .message-container {
    width: 95%;
  }

  #contact-submit {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .contact-main-title {
    font-size: 3em;
    margin-top: 45px;
  }

  .contact-background-title {
    font-size: 5.4em;
  }

  #contact-form,
  .contact-information-title,
  .contact-email,
  .contact-phone,
  .contact-socials-container {
    width: 80%;
    margin: auto;
  }

  #contact-form {
    max-width: 1200px;
  }

  .contact-message {
    width: 100%;
  }
}
