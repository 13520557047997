::-webkit-scrollbar {
  display: none;
}

body {
  background-color: var(--neutral_100);
}

.container {
  min-height: 100vh;
  background-color: var(--neutral_100);
}

.saveme-link {
  color: var(--primary_500);
  text-decoration: none;
}
.saveme-link:hover {
  text-decoration: underline;
}

.saveme-header {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding-top: 6px;
}

.hamburger-menu {
  position: fixed;
}

.saveme-header-figure {
  margin: 0;
  width: 60px;
  height: auto;
  display: flex;
  justify-content: center;
}

.saveme-header-image {
  width: 100%;
  height: 100%;
  color: var(--neutral_100);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.8));
}

#saveme-menu__toggle {
  opacity: 0;
}
#saveme-menu__toggle:checked + .saveme-menu__btn > span {
  transform: rotate(45deg);
}
#saveme-menu__toggle:checked + .saveme-menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#saveme-menu__toggle:checked + .saveme-menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#saveme-menu__toggle:checked ~ .saveme-menu__box {
  right: 0 !important;
}
.saveme-menu__btn {
  position: fixed;
  top: 20px;
  right: 35px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}
.saveme-menu__btn > span,
.saveme-menu__btn > span::before,
.saveme-menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 18px;
  background-color: var(--neutral_100);
  transition-duration: 0.5s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}
.saveme-menu__btn > span::before {
  content: "";
  top: -10px;
}
.saveme-menu__btn > span::after {
  content: "";
  top: 10px;
}

.saveme-menu__socials_container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
saveme- #saveme-menu__toggle:checked + .saveme-menu__btn > span,
#saveme-menu__toggle:checked + .saveme-menu__btn > span::before,
#saveme-menu__toggle:checked + .saveme-menu__btn > span::after {
  background-color: var(--primary_500);
  box-shadow: unset;
}

.saveme-menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: calc(100vh - 75px);
  margin: 0;
  padding: 80px 0 0 0;
  list-style: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.5s;
  background-color: var(--neutral_100);
}

.saveme-menu__item {
  color: var(--primary_500);
  font-weight: 500;
  display: block;
  padding: 24px 24px 6px;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  width: fit-content;
  margin: auto;
}

.banner-container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ee4f58 0%,
    var(--primary_500) 100%
  );
  padding-bottom: 64px;
}

.saveme-banner-figure {
  padding: 0;
  margin: 0;
  width: 100vw;
  max-width: 400px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  align-self: center;
}

.saveme-banner-image {
  width: 150%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  pointer-events: none;
}

.banner-content-container {
  display: flex;
  flex-direction: column;
}

.banner-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.banner-title {
  color: var(--neutral_100);
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  margin: 0;
}

.banner-text {
  color: var(--neutral_100);
  font-size: 1rem;
  font-family: "poppins", sans-serif;
  font-weight: 300;
  line-height: 1.2rem;
}

.button-solid {
  text-decoration: none;
  color: var(--neutral_900);
  background-color: var(--neutral_100);
  padding: 8px 12px;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}

.button-solid:hover {
  background-color: var(--primary_600);
  color: var(--neutral_100);
}

.button-outline {
  text-decoration: none;
  color: var(--neutral_100);
  border: 3px solid var(--neutral_100);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 6px 9px;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-weight: 500;
}

.button-outline:hover {
  background-color: var(--neutral_100);
  color: var(--primary_500);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.section {
  padding-top: 64px;
  padding-bottom: 64px;
}

.what-container {
  padding-bottom: 0;
}

.content-container {
  max-width: 912px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 12px;
}

.what-figure {
  display: none;
}

.explainer-swiper-container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ee4f58 0%,
    var(--primary_500) 100%
  );
  border-radius: 15px;
}

.explainer-step-container {
  overflow: hidden;
  padding-bottom: 12px;
}

.explainer-figure {
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: auto;
}

.explainer-image {
  width: 150%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.explainer-swiper-container .swiper-pagination {
  padding-bottom: 64px !important;
  width: auto;
}

.explainer-swiper-container .swiper-pagination-bullet-active {
  background: var(--swiper-pagination-color, var(--neutral_100));
}

.explainer-swiper-container .swiper-pagination-bullet-active-prev,
.explainer-swiper-container .swiper-pagination-bullet-active-next,
.explainer-swiper-container .swiper-pagination-bullet-active-next-next,
.explainer-swiper-container .swiper-pagination-bullet-active-prev-prev {
  border: 1px solid var(--neutral_100) !important;
}

.explainer-swiper-container .swiper-button-next {
  right: 12px;
  top: 200px;
  color: var(--neutral_100);
  text-shadow: none;
}
.explainer-swiper-container .swiper-button-prev {
  left: 12px;
  top: 200px;
  color: var(--neutral_100);
  text-shadow: none;
}

.explainer-swiper-container .swiper-button-disabled {
  opacity: 0 !important;
}

.explainer-swiper-container .swiper-notification {
  display: none;
}

.saveme-promovideo {
  width: 100%;
  height: 43vw;
}

.faq-intro {
  margin-bottom: 32px;
}

.question-container {
  margin-bottom: 28px;
}

.question-header {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
}

.question-header:hover {
  cursor: pointer;
}

.faq-question {
  margin-bottom: 0;
  display: flex;
  flex-shrink: 1;
}

.info-list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
}
.info-cards-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.why-info-card {
  padding: 0px 10px;
  background-color: var(--neutral_200);
  border-radius: 8px;
  width: fit-content;
}

.saveme-about-figure {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  width: 50%;
  max-width: 200px;
  position: unset;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: auto;
}

.saveme-about-image {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.partner-section {
  padding-bottom: 24px;
}

.partner-figure {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  width: 50%;
  max-width: 200px;
  position: unset;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.partner-image {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.footer-container {
  background-color: var(--primary_500);
  padding-top: 24px;
}

.footer-figure {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  width: 30%;
  max-width: 125px;
  position: unset;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: auto;
}

.footer-image {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.footer-section {
  padding-bottom: 0;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}
.footer-link-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  gap: 5px;
}
.footer-link {
  text-decoration: none;
  color: var(--primary_300);
  padding: 5px 0px;
}
.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  z-index: 1000;
}
.menu-link {
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1.1rem;
  color: var(--neutral_900);
  font-weight: 300;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.menu-link:hover {
  background-color: var(--neutral_300);
}
.saveme-copyright-disclaimer {
  bottom: 0;
  color: #000;
  margin: auto;
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 24px;
  padding: 0;
  position: static;
}
.saveme-copyright-disclaimer a {
  color: #000;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .banner-content-container {
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
  }

  .banner-content {
    align-self: center;
    margin: auto;
    padding-top: 32px;
    max-width: 400px;
  }

  .mockup-placeholder {
    width: 100vw;
    aspect-ratio: 1.125;
    width: 50vw;
    max-width: 500px;
  }

  .saveme-banner-figure {
    position: absolute;
    width: 50vw;
    max-width: 600px;
    overflow: unset;
    align-self: unset;
  }

  .what-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .what-figure {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: hidden;
  }

  .what-image {
    width: 150%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
  }

  .what-container > div {
    max-width: 400px;
  }

  .explainer-step-container {
    display: flex;
    flex-direction: row;
  }

  .explainer-figure {
    order: 1;
  }

  .explainer-step-content-container {
    padding-top: 64px;
    padding-left: 24px;
  }

  .saveme-promovideo {
    width: 100%;
    height: 350px;
  }

  .info-list-container {
    flex-direction: row;
  }

  .saveme-about-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
  }

  .saveme-footer-container {
    display: flex;
    flex-direction: row;
    gap: 64px;
  }
  .footer-figure {
    margin: 0;
  }

  .footer-links-container {
    margin: 0;
    gap: 64px;
  }

  .footer-link-section {
    width: unset;
    margin: auto;
    align-items: start;
  }
}
