.about-screen-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.about-content-container {
    position: absolute;
    background-image: linear-gradient(var(--black-20) 15%, transparent 50%, transparent 90%, var(--black-20));
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
}

.about-titles {
    position: relative;
    margin: auto;
    padding-top: 24px;
    display: flex;
    align-items: baseline;
    padding: 12px 24px 0 24px;
}

.about-main-title {
    margin: 0;
    margin-top: 30px;
    color: var(--white);
    font-weight: 600;
    font-size: 2em;
    text-shadow: 0px 0px 10px var(--black-75);
    z-index: 1;
}

.about-background-title {
    position: absolute;
    display: block;
    margin: 0;
    font-size: max(3.6em);
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke: 0.3px var(--yellow);
}

.about-hey {
    color: var(--white);
    font-size: 1.4em;
    font-weight: 200;
    padding: 12px 24px 0 24px;
}

.about-text {
    color: var(--white);
    font-weight: 200;
    padding: 12px 24px 0 24px;
}

.about-devider-left {
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, var(--yellow) 40%, transparent 60%);
    bottom: 8%;
    z-index: 1;
}

.about-devider-right {
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, transparent 20%, var(--yellow) 40%);
    bottom: 5%;
    z-index: 1;
}

@media only screen and (min-width: 768px){

    .about-titles {
        padding: 12px 48px 0 48px;
    }

    .about-hey {
        padding: 12px 48px 0 48px;
    }

    .about-text {
        width: 60%;
        padding: 12px 48px 0 48px;

    }

}

@media only screen and (min-width: 1024px){


.about-content-container {
    background-image: linear-gradient(var(--black-20) 15%, transparent 50%, transparent 60%, var(--black-20) 95%);

}
    .about-main-title {
        font-size: 3em;
        margin-top: 45px;
    }

    .about-background-title {
        font-size: 5.4em;
    }

    .about-text {
        width: 40%;
    }

}