::-webkit-scrollbar {
  width: 5px;
  transition: 0.3s ease;
}

::-webkit-scrollbar:hover {
  width: 20px;
  transition: 0.3s ease;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background: var(--yellow);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-yellow);
  cursor: pointer;
}

.app-container {
  position: relative;
}
